import React, { useEffect, useState } from "react";
import { Project } from "store/types/projects";
import Window from "components/projects/project explorer/Window";

const Projects = () => {
  return (
    <>
      <Window />
    </>
  );
};

export default Projects;
