import React from "react";
import { Project } from "../../../../store/types/projects";

const ProjectTile = ({ project }: { project: Project }) => {
  return (
    <article>
      <h2>{project.name}</h2>
      <img />
      {project.description}
    </article>
  );
};

export default ProjectTile;
