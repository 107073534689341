import React, { useEffect, useState } from "react";
import { Skill } from "store/types/skills";
import TechnologyIcon from "./TechnologyIcon";
import getImageUrl from "helpers/getImageUrl";

export type Technology = {
  name: string;
  tooltip: string;
  related_skill: Skill;
  icon_filename: string;
};

const Technologies = () => {
  //not the most efficient way, but do not want to write more code :P
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [skills, setSkills] = useState<Skill[]>();

  useEffect(() => {
    let mount = true;
    const technologiesRequest = fetch("/api/v1/technologies").then((response) =>
      response.json()
    );
    // .then((technologies) => {
    //   if (mount) {
    //   }
    // })

    const skillsRequest = fetch("/api/v1/skills").then((response) =>
      response.json()
    );
    // .then((skills) => {
    //   if (mount) {
    //     setSkills(skills);
    //   }
    // })

    Promise.all([technologiesRequest, skillsRequest]).then(
      ([technologies, skills]) => {
        if (mount) {
          setTechnologies(technologies);
          setSkills(skills);
        }
      }
    );

    return () => {
      mount = false;
    };
  }, []);

  const technologiesGroupedPerSkill = technologies.reduce(
    (acc: Map<string, Technology[]>, technology) => {
      const skill = technology.related_skill.name;
      if (acc.has(skill)) {
        const technologyArr = acc.get(skill);
        if (!technologyArr) {
          throw new Error("the technology array is undefined");
        }
        technologyArr.push(technology);
      } else {
        acc.set(technology.related_skill.name, [technology]);
      }
      return acc;
    },
    new Map<string, Technology[]>()
  );

  const skillsGroupedPerTopic = skills?.reduce(
    (acc: Map<string, Skill[]>, skill) => {
      const topicName = skill.topic.name;
      if (acc.has(topicName)) {
        const skillsForTopic = acc.get(topicName);
        if (!skillsForTopic) {
          throw new Error("the skills array is not present, logic error");
        }
        skillsForTopic.push(skill);
      } else {
        acc.set(topicName, [skill]);
      }
      return acc;
    },
    new Map<string, Skill[]>()
  );

  console.log(technologiesGroupedPerSkill);
  console.log("skillsgrouped");
  console.log(skillsGroupedPerTopic);

  return (
    <>
      <div id="technologies-container">
        {skillsGroupedPerTopic &&
          technologiesGroupedPerSkill &&
          [...skillsGroupedPerTopic.keys()].map((topicName) => {
            const skills = skillsGroupedPerTopic.get(topicName);
            return (
              <div key={topicName} className="card">
                <h3>{topicName}</h3>
                {skills?.map((skill) => (
                  <article className="article-container" key={skill.id}>
                    {skill.name}
                    <div className="technologyGroupedPerSkill-container">
                      {technologiesGroupedPerSkill
                        .get(skill.name)
                        ?.map((technology) => {
                          const url = getImageUrl(
                            `icons/${technology.icon_filename}.svg`
                          );
                          return (
                            <TechnologyIcon
                              key={technology.name}
                              src={url}
                              name={technology.name}
                              tooltip={technology.tooltip}
                            />
                          );
                        })}
                    </div>
                  </article>
                ))}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Technologies;
