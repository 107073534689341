import cv from "../assets/dummy_cv_light.pdf";
import "./CV.css";

//credits: https://pspdfkit.com/blog/2021/how-to-embed-a-pdf-viewer-in-your-website/
const CV = () => {
  const cvFullWidth = cv + "#view=FitH";
  return (
    <div id="cv-main-container" className="section">
      <a href={cv} download="cv_bjorn_van_braak" className="btn">
        Download cv
      </a>
      <div id="pdf-cv-preview">
        <div style={{ width: "100%", height: "100%" }}>
          <object
            data={cvFullWidth}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <iframe
              src={cvFullWidth}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            >
              <p>
                Your browser does not support PDFs.
                <a href={cv}>Download the PDF</a>
              </p>
            </iframe>
          </object>
        </div>
      </div>
    </div>
  );
};

export default CV;
