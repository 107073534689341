import { useContext } from "react";
import {
  FilterOptions,
  ProjectExplorerSettingsContext,
} from "store/ProjectExplorerSettingsContext";
import { fileSeparator, root } from "../Window";
import { Project } from "store/types/projects";
import { Skill } from "store/types/skills";
import ProjectDisplay from "./ProjectDisplay";
import ProjectHub from "./ProjectHub";

const filterOnSkill = (projects: Project[], filterSetting: FilterOptions) => {
  if (filterSetting === "NONE") {
    return projects;
  }
  const filterSkills: Skill[] = filterSetting;
  const filteredProjects = projects.filter((project) =>
    project.skills.some((skill) => {
      if (!filterSkills) {
        return project;
      }
      return filterSkills.some(
        (filterSkill: Skill) => filterSkill.id === skill.id
      );
    })
  );
  return filteredProjects;
};

const filterOnDirectory = (
  projects: Project[],
  directory: string
): Project[] => {
  if (!directory) {
    return projects;
  } else {
    const paths = directory.split(fileSeparator);
    if (paths.length === 1) {
      const folder = paths[0];
      const projectsInScope = projects.filter(
        (project: Project) => project.folder_name === folder
      );
      return projectsInScope;
    } else {
      //temp not sure what to do with multiple layers
      const name = paths.at(-1);
      const currentProject = projects.find((project) => project.name === name);
      if (!currentProject) {
        return [];
      } else {
        return [currentProject];
      }
    }
  }
};

const findProjectsInScope = (
  projects: Project[],
  directory: string,
  filterSetting: FilterOptions
) => {
  const filteredOnSkills = filterOnSkill(projects, filterSetting);
  const filteredProjects = filterOnDirectory(filteredOnSkills, directory);
  return filteredProjects;
};

const ProjectExplorerDisplay = ({ projects, filePath }) => {
  const {
    settings: { filter },
  } = useContext(ProjectExplorerSettingsContext);

  const projectsInScope = findProjectsInScope(
    projects,
    filePath.split(root)[1],
    filter
  );
  return (
    <>
      {projectsInScope.length === 0 ? (
        <p>no project results</p>
      ) : projectsInScope.length === 1 ? (
        <ProjectDisplay currentProject={projectsInScope[0]} />
      ) : (
        <ProjectHub projects={projectsInScope} />
      )}
    </>
  );
};

export default ProjectExplorerDisplay;
