import React from "react";

function SkillTag({ color, skill }) {
  const styleTag = {
    backgroundColor: color,
    display: "inline-block",
    borderRadius: "0.5rem",
    padding: "0.1rem 0.5rem 0.1rem 0.5rem",
    margin: "0 0.1rem 0 0.1rem",
  };
  return <div style={styleTag}>{skill}</div>;
}

export default SkillTag;
