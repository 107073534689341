import React, { useState } from "react";
function Collapsible({
  folderName,
  open,
  handleCollapsible,
  handleChangeDirectory,
  children,
}) {
  return (
    <details
      className="topicContainer"
      open={open}
      onClick={(e) => {
        handleCollapsible(e, folderName);
      }}
    >
      <summary>
        <a
          href={`#${folderName}`}
          onClick={() => {
            handleChangeDirectory(folderName);
          }}
        >
          {folderName}
        </a>
      </summary>
      {
        <ul
          className="sidebarProjectContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </ul>
      }
    </details>
  );
}

export default Collapsible;
