import getImageUrl from "helpers/getImageUrl";
import "./LoadingSpinner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingSpinner = ({ replaceText = "" }) => {
  return (
    <div className="loading-spinner">
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
};

export default LoadingSpinner;
