import { useEffect, useState } from "react";
import ScrollDown from "../components/ScrollDown.tsx";
import ProjectCarousel from "../components/home/ProjectCarousel.tsx";
import fotoCv from "../assets/foto_cv.jpeg";

import "./Home.css";
import { Link } from "react-router-dom";
import Skills from "../components/Skills.tsx";
import { Project } from "store/types/projects.ts";

const Home = () => {
  useEffect(() => {
    //credits: https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
    // Create the observer
    const observer = new IntersectionObserver((entries) => {
      // We will fill in the callback later...
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          entry.target.classList.add("animate-fadein");
        }
      });
    });

    // Tell the observer which elements to track
    const left = document.querySelectorAll(".fadeInLeft");
    const right = document.querySelectorAll(".fadeInRight");
    const all = [...left, ...right];
    if (!all) {
      throw new Error("no skills defined");
    }
    all.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  return (
    <>
      <div id="homepage-hero" className="hero">
        <div className="carousel-container">
          <ProjectCarousel />
        </div>
        <ScrollDown />
      </div>
      <div id="homepage-about-view" className="section">
        <article className="text-image-side-by-side card">
          <div className="fadeInLeft">
            <h1>Bjorn van Braak</h1>
            <p>
              I’m currently a student of the university of Twente. My study is
              Msc Business Information Technology (BIT), which combines the
              vision of business with the power of technology. As a hobby I like
              to develop web pages.
            </p>
            <Link to="/about" className="btn">
              About me
            </Link>
          </div>
          <div
            id="profile-image-container"
            className="mobile-section fadeInRight"
          >
            <img
              alt="profile picture of Bjorn van Braak"
              src={fotoCv}
              width={1236}
              height={1648}
            />
          </div>
        </article>
      </div>
      <div id="homepage-skills-view" className="section card">
        <Skills
          styleContainer={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
          showSubSkills={false}
        />
      </div>
      <div id="homepage-further-questions-view" className="section">
        <article className="text-image-side-by-side card">
          <div className="fadeInLeft">
            <iframe />
          </div>
          <div className="mobile-section fadeInRight">
            <h1>Any other questions?</h1>
            <p>
              <i>
                Were you not able to find the information you were looking for?
              </i>
              <br />
              <br />
              Ask the chatbot!
              <br />
              <br />
              OR...
              <br />
              <br />
              <Link to="/contact" className="btn">
                Contact me{" "}
              </Link>
            </p>
          </div>
        </article>
      </div>
    </>
  );
};

export default Home;
