import { Tooltip } from "react-tooltip";

const TechnologyIcon = ({ src, name, tooltip }) => {
  return (
    <>
      <div>
        <img
          src={src}
          width={60}
          height={60}
          style={{ width: 60, height: 60 }}
          alt={`technology used ${name}`}
        />
        <p
          style={{ borderBottom: "1px dashed" }}
          data-tooltip-id={`technology-info-${name}`}
          data-tooltip-content={tooltip}
        >
          {name}
        </p>
        <Tooltip
          id={`technology-info-${name}`}
          style={{
            backgroundColor: "var(--tooltip-background-color)",
            color: "var(--tooltip-color)",
            zIndex: 10,
          }}
        />
      </div>
    </>
  );
};

export default TechnologyIcon;
