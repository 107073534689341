import { useEffect, useState } from "react";
import ProjectExplorerSettingsProvider from "store/ProjectExplorerSettingsProvider";
import ProjectExplorer from "./ProjectExplorer";
import { Project } from "store/types/projects";

let isUsersOperatingSystemWindows = false;
if (window.navigator.userAgent.toLowerCase().indexOf("windows")) {
  isUsersOperatingSystemWindows = true;
}
export const fileSeparator = isUsersOperatingSystemWindows ? "\\" : "/";

export const root = `explorer:${fileSeparator}`;

/**
 * Fetches skills and projects, and establishes the settings context
 * @returns
 */
const Window = () => {
  const [projects, setProjects] = useState<Project[]>();
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/skills")
      .then((response) => response.json())
      .then((skills) => {
        if (mount) {
          setSkills(skills);
        }
      })
      .catch((err) => {
        console.error("unable to fetch Skills, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/projects")
      .then((response) => response.json())
      .then((projects) => {
        if (mount) {
          setProjects(projects);
        }
      })
      .catch((err) => {
        console.error("unable to fetch projects, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);

  if (!projects) {
    return <p>window placeholder element...</p>;
  }

  return (
    <ProjectExplorerSettingsProvider skills={skills}>
      <ProjectExplorer projects={projects} skills={skills} />
    </ProjectExplorerSettingsProvider>
  );
};

export default Window;
