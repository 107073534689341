import React, { useContext } from "react";
import ProjectTile from "./ProjectTile";
import { ProjectExplorerSettingsContext } from "../../../../store/ProjectExplorerSettingsContext";
import { Project } from "../../../../store/types/projects";

const sortProjects = (sortOption: string, projects: Project[]) => {
  const projectsCopy = projects.concat();
  switch (sortOption) {
    case "FOLDER":
      return projectsCopy.sort((a, b) =>
        a.folder_name.localeCompare(b.folder_name)
      );
    case "NAME":
      return projectsCopy.sort((a, b) => a.name.localeCompare(b.name));
    case "DIFFICULTY":
      return projectsCopy.sort((a, b) => {
        if (a.difficulty_rating < b.difficulty_rating) {
          return 1;
        }
        if (a.difficulty_rating > b.difficulty_rating) {
          return -1;
        }
        return 0;
      });
    case "DATE_MODIFIED_ASC":
      return projectsCopy.sort((a, b) => {
        const aDate = new Date(a.date_modified);
        const bDate = new Date(b.date_modified);
        if (aDate.getTime() < bDate.getTime()) {
          return 1;
        }
        if (aDate.getTime() > bDate.getTime()) {
          return -1;
        }
        return 0;
      });
    case "DATE_MODIFIED_DESC":
      return projectsCopy.sort((a, b) => {
        const aDate = new Date(a.date_modified);
        const bDate = new Date(b.date_modified);
        if (aDate.getTime() > bDate.getTime()) {
          return 1;
        }
        if (aDate.getTime() < bDate.getTime()) {
          return -1;
        }
        return 0;
      });
    default:
      throw new Error("unknown sort option");
  }
};

function ProjectHub({ projects }) {
  const { settings } = useContext(ProjectExplorerSettingsContext);

  // const filteredProjects =
  //   settings.filter === "NONE"
  //     ? projects
  //     : projects.filter((project: Project) =>
  //         project.skills.some((skill) => skill.id === settings.filter.id)
  //       );
  const sortedProjects = sortProjects(settings.sort, projects);
  return (
    <>
      <h1>hub</h1>
      {settings.view === "TILES" ? (
        <ul>
          {sortedProjects.map((project) => (
            <li key={project.name}>
              <ProjectTile project={project} />
            </li>
          ))}
        </ul>
      ) : (
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th>topic</th>
              <th>description</th>
              <th>difficulty</th>
              <th>date modified</th>
            </tr>
          </thead>
          <tbody>
            {sortedProjects.map((project) => (
              <tr key={project.name}>
                <td>{project.name}</td>
                <td>{project.topic}</td>
                <td>{project.shortDescription}</td>
                <td>{project.difficulty_rating}</td>
                <td>
                  {project.date_modified
                    ? project.date_modified.toString()
                    : "unknown"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default ProjectHub;
