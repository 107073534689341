import React from "react";
import { ThemeContext } from "../store/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { faSun } from "@fortawesome/free-solid-svg-icons";

const ThemeToggle = () => {
  const themeCtx = React.useContext(ThemeContext);
  return (
    <button
      onClick={() => {
        themeCtx.toggleTheme();
      }}
    >
      {themeCtx.theme === "light" ? (
        <FontAwesomeIcon icon={faMoon} fixedWidth />
      ) : (
        <FontAwesomeIcon icon={faSun} fixedWidth />
      )}
    </button>
  );
};

export default ThemeToggle;
