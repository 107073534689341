import Carousel from "../Carousel";
import { useEffect, useState } from "react";
import { Project } from "store/types/projects";
const aspectratio = 16 / 9;
import { ProjectImageInfo } from "../../store/types/projectImageInfo";
import getImageUrl from "helpers/getImageUrl";

const ProjectCarousel = () => {
  const [projects, setProjects] = useState<Project[]>();

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/projects/highlighted")
      .then((response) => response.json())
      .then((projects) => {
        if (mount) {
          setProjects(projects);
        }
      })
      .catch((err) => {
        console.error("unable to fetch projects, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);
  const images = projects?.map((project) => {
    const projectImageInfo: ProjectImageInfo = {
      src: getImageUrl(project.image_filename_sub_prefix + "-500.jpeg"),
      description: project.short_description,
      onclick: (e: MouseEvent) => {
        return;
      },
    };
    return projectImageInfo;
  });
  if (!images) {
    return <p> no images</p>;
  }
  return (
    <Carousel
      images={images}
      aspectratio={aspectratio}
      totalViewportWidthCarousel={100}
      originalImageWidth={500}
    />
  );
};

export default ProjectCarousel;
