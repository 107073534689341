import "./ProjectExplorer.css";

import React, { useState, useContext } from "react";
import SideNavbar from "./sidenav/SideNavbar";
import { Project } from "../../../store/types/projects";
import { useLocation, useNavigate } from "react-router";
import {
  FilterOptions,
  ProjectExplorerSettingsContext,
} from "../../../store/ProjectExplorerSettingsContext";
import ToolbarNav from "./toolbar/ToolbarNav";
import ToolbarOptions from "./toolbar/ToolbarOptions";
import { Skill } from "store/types/skills";
import ProjectExplorerDisplay from "./display/ProjectExplorerDisplay";
import { fileSeparator, root } from "./Window";

const getProjectGroupedByTopicMap = (projects: Project[]) => {
  const folderNames = projects.reduce((acc, project) => {
    if (acc.includes(project.folder_name)) {
      return acc;
    } else {
      return [...acc, project.folder_name];
    }
  }, []);
  const topicToProjectsMap = new Map<string, Project[]>();
  for (const folderName of folderNames) {
    const projectsInTopic = [];
    for (const project of projects) {
      if (project.folder_name === folderName) {
        projectsInTopic.push(project);
      }
    }
    topicToProjectsMap.set(folderName, projectsInTopic);
  }
  return topicToProjectsMap;
};

// const settingsReducer = (settings: Settings, action: SetSetting): Settings => {
//   switch (action.type) {
//     case "SORT":
//       return { ...settings, sort: action.option };
//     case "FILTER":
//       return { ...settings, filter: action.option };
//     case "VIEW":
//       return { ...settings, view: action.option };
//     case "CONFIG":
//       return {
//         ...settings,
//         config: { ...settings.config, [action.setting]: action.option },
//       };
//     default:
//       throw new Error("unknown settting in project explorer");
//   }
// };

const getSearchHash = (search: string, hash: string) => {
  if (
    (search !== "" && !search.startsWith("?")) ||
    (hash !== "" && !hash.startsWith("#"))
  ) {
    throw new Error(
      "unvalid format for either search or hash: " + search + hash
    );
  }
  const searchHash = search + hash;
  return searchHash;
};

function ProjectExplorer({
  projects,
  skills,
}: {
  projects: Project[];
  skills: Skill[];
}) {
  const [history, setHistory] = useState<string[]>([""]);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const { dispatchSetting } = useContext(ProjectExplorerSettingsContext);

  const initial_directory =
    hash.length > 1
      ? hash.split("#")[1].replaceAll("%20", " ").replaceAll("-", fileSeparator)
      : "";
  //current displayed directory
  const [filePath, setFilePath] = useState<string>(root + initial_directory);

  const projectsGroupedByTopicMap = getProjectGroupedByTopicMap(projects);

  //change the display to the view with the corresponding name
  const changeDirectory = (directory: string) => {
    if (directory === "") {
      setFilePath(root);
    } else {
      setFilePath(root + directory);
    }
  };

  const addToHistory = (directory: string) => {
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, activeIndex + 1);
      newHistory.push(directory);
      return newHistory;
    });
  };

  const goBackwards = () => {
    //change active link in history
    const newIndex = activeIndex - 1;
    if (newIndex < 0) {
      //cannot go backwards
      return;
    }
    const activeDirectory = history[newIndex];
    changeDirectory(activeDirectory);
    setActiveIndex(newIndex);
    navigate(getSearchHash(search, "#" + activeDirectory));
  };

  const goForwards = () => {
    //change active link in history
    const newIndex = activeIndex + 1;
    if (newIndex >= history.length) {
      //cannot go forwards
      return;
    }
    const activeDirectory = history[newIndex];
    changeDirectory(activeDirectory);
    setActiveIndex(newIndex);
    navigate(getSearchHash(search, "#" + activeDirectory));
  };

  const goUpOneLevel = () => {
    const paths = filePath.split(fileSeparator);
    if (paths.length === 2 && paths[1].length === 0) {
      //already at highest level
      return;
    }
    const upOneLevelDirectory = paths.slice(1, -1).join(fileSeparator); //excludes the first path since that is the root
    addToHistory(upOneLevelDirectory);
    const newIndex = activeIndex + 1;
    setActiveIndex(newIndex);
    changeDirectory(upOneLevelDirectory);
    navigate(getSearchHash(search, "#" + upOneLevelDirectory));
  };

  const handleChangeDirectory = (directory: string) => {
    // const name = directory.split(separator).at(-1);
    changeDirectory(directory);
    addToHistory(directory);
    const newIndex = activeIndex + 1;
    setActiveIndex(newIndex);
  };

  const handleChangeFilter = (filter: FilterOptions) => {
    const search =
      filter === "NONE"
        ? "?"
        : "?filter_skill_id=" +
          filter.map(
            (skill: Skill, index: number) =>
              "" + skill.id + (index !== filter.length - 1 ? "&" : "")
          );
    navigate(getSearchHash(search, hash));
    dispatchSetting({ type: "FILTER", option: filter });
  };

  return (
    <div id="project-explorer-container">
      <header id="toolbar-container">
        <ToolbarNav
          goBackwards={goBackwards}
          goForwards={goForwards}
          goUpOneLevel={goUpOneLevel}
          handleChangeDirectory={handleChangeDirectory}
          filePath={filePath}
          projectsGroupedByTopicMap={projectsGroupedByTopicMap}
        />
        <ToolbarOptions
          skills={skills}
          handleChangeFilter={handleChangeFilter}
        />
      </header>
      <div id="sidenavbar-container">
        <SideNavbar
          handleChangeDirectory={handleChangeDirectory}
          projectsGroupedByFolderNameMap={projectsGroupedByTopicMap}
        />
      </div>
      <div id="display-container">
        <ProjectExplorerDisplay projects={projects} filePath={filePath} />
      </div>
    </div>
  );
}

export default ProjectExplorer;
