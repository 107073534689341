import "./About.css";
import Skills from "../components/Skills";
import useTypewriter from "../hooks/useTypewriter";
import Timeline from "../components/about/Timeline";

// import azureIcon from "../assets/icons/azure.svg";
// import devopsIcon from "../assets/icons/devops.svg";
// import excelIcon from "../assets/icons/excel.svg";
// import javascriptIcon from "../assets/icons/javascript.svg";
// import nodeIcon from "../assets/icons/node.svg";
// import pythonIcon from "../assets/icons/python.svg";
// import reactIcon from "../assets/icons/react.svg";
// import scrumIcon from "../assets/icons/scrum.svg";
// import typescriptIcon from "../assets/icons/typescript.svg";

import Technologies from "components/about/Technologies";

const text = "Hello I am Bjorn van Braak";

const About = () => {
  const displayText = useTypewriter(text, 40);
  return (
    <>
      <div id="about-long-introduction" className="hero">
        <article>
          <div
            className={`typewriter ${
              text === displayText ? "finished" : "writting"
            }`}
          >
            <h1 className="text-wrap-pretty">{displayText}</h1>
          </div>
          <p
            className={`text-wrap-pretty appear-animation ${
              text === displayText ? "visible" : "hidden"
            }`}
          >
            I’m Bjorn, a problem-solving enthusiast. My approach involves
            finding a good-enough solution that fits the problem at hand.
            Curious about how I approach solving problems? Please take a look!
          </p>
        </article>
      </div>
      <div id="about-skills" className="section card">
        <h2>Skills</h2>
        <Skills showSubSkills={true} />
      </div>
      <div id="about-technologies" className="section card">
        <h2>Technologies</h2>
        <Technologies />
      </div>
      <div id="about-timeline" className="section">
        <Timeline />
      </div>
      {/* {azureIcon} */}
    </>
  );
};

export default About;
