import "./Contact.css";
import ContactForm from "components/contact/ContactForm";

const highlightSocials = () => {
  const socials = document.querySelector("#socials-container");

  socials?.classList.add("highlight");
  setTimeout(() => {
    socials?.classList.remove("highlight");
  }, 3000);
};

const Contact = () => {
  return (
    <div id="contact-container" className="section">
      <article id="contact-form-container">
        <h1>Get in contact</h1>
        <p>
          You can get into contact with me either by filling in this form or
          through my{" "}
          <a href="#socials-container" onClick={highlightSocials}>
            socials
          </a>
        </p>
        <ContactForm />
      </article>
      <div id="chatbot-container" className="mobile-section">
        <iframe />
      </div>
    </div>
  );
};

export default Contact;
