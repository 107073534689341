import Skillbar from "./Skillbar";
import "./SkillStack.css";

const SkillStack = ({ skill, showSubSkills }) => {
  const skillElement = (
    <>
      <Skillbar name={skill.name} rating={skill.rating} />
      <a href={`projects?filter_skill_id=${skill.id}`}>learn more</a>
    </>
  );

  return showSubSkills ? (
    <details className="skillstack">
      <summary
        className={`${
          skill["subskills"] === undefined ? "marker-hidden" : "marker-visible"
        }`}
      >
        {skillElement}
      </summary>
      {skill["subskills"] !== undefined && skill["subskills"].length !== 0 && (
        <div className={`subskills-container`}>
          {skill["subskills"].map((subskill) => {
            return (
              <Skillbar
                key={subskill.id}
                name={subskill.name}
                rating={subskill.rating}
              />
            );
          })}
        </div>
      )}
    </details>
  ) : (
    <article>{skillElement}</article>
  );
};

export default SkillStack;
