import SkillTag from "./SkillTag";
import { Skill } from "../../../../store/types/skills";

function GeneralProjectInfo({
  name,
  skills,
  description,
}: {
  name: string;
  skills: Skill[];
  description: string;
}) {
  return (
    <>
      <h1>{name}</h1>
      {skills.map((skill, index) => {
        return <SkillTag key={index} color={skill.color} skill={skill.name} />;
      })}
      <p>{description}</p>
    </>
  );
}

export default GeneralProjectInfo;
