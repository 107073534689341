import { Children, Reducer, useReducer } from "react";
import {
  DEFAULT_SETTINGS,
  FilterOptions,
  ProjectExplorerSettingsContext,
  SetSetting,
  Settings,
} from "./ProjectExplorerSettingsContext";
import { useLocation } from "react-router";
import { Skill } from "./types/skills";

const settingsReducer = (settings: Settings, action: SetSetting): Settings => {
  switch (action.type) {
    case "SORT":
      return { ...settings, sort: action.option };
    case "FILTER":
      return { ...settings, filter: action.option };
    case "VIEW":
      return { ...settings, view: action.option };
    case "CONFIG":
      return {
        ...settings,
        config: { ...settings.config, [action.setting]: action.option },
      };
    default:
      throw new Error("Unknown setting in project explorer");
  }
};

const ProjectExplorerSettingsProvider = ({ skills, children }) => {
  const { search } = useLocation();
  const searchFilterSkillId = new URLSearchParams(search).get(
    "filter_skill_id"
  );
  const searchSkill = skills.find(
    (skill) => String(skill.id) === searchFilterSkillId
  );
  const initialFilter =
    searchFilterSkillId && searchSkill ? [searchSkill] : "NONE";

  const initial_settings: Settings = {
    ...DEFAULT_SETTINGS,
    filter: initialFilter,
  };

  const [settings, dispatchSetting] = useReducer<Reducer<Settings, SetSetting>>(
    settingsReducer,
    initial_settings,
    undefined
  );
  return (
    <ProjectExplorerSettingsContext.Provider
      value={{ settings, dispatchSetting }}
    >
      {children}
    </ProjectExplorerSettingsContext.Provider>
  );
};

export default ProjectExplorerSettingsProvider;
