import { createContext } from "react";
import { Skill } from "./types/skills";

export enum ViewOptions {
  TILES = "TILES",
  LIST = "LIST",
}

export enum SortOptions {
  FOLDER = "FOLDER",
  NAME = "NAME",
  DIFFICULTY = "DIFFICULTY",
  DATE_MODIFIED_ASC = "DATE_MODIFIED_ASC",
  DATE_MODIFIED_DESC = "DATE_MODIFIED_DESC",
}
export type FilterOptions = Skill[] | "NONE";

export const DEFAULT_SETTINGS: Settings = {
  sort: SortOptions.FOLDER,
  filter: "NONE",
  view: ViewOptions.TILES,
  config: { TEST: "TEST_VALUE" },
};

export type SetSetting =
  | {
      type: "SORT";
      option: SortOptions;
    }
  | {
      type: "FILTER";
      option: FilterOptions;
    }
  | {
      type: "VIEW";
      option: ViewOptions;
    }
  | {
      type: "CONFIG";
      setting: "TEMP";
      option: "TEST_VALUE";
    };

export type Settings = {
  sort: SortOptions;
  filter: Skill[] | "NONE";
  view: ViewOptions;
  config: object;
};

export const ProjectExplorerSettingsContext = createContext({
  settings: DEFAULT_SETTINGS,
  dispatchSetting: (setting: SetSetting) => {},
});
