import React from "react";
import GeneralProjectInfo from "./GeneralProjectInfo";
import { Project } from "../../../../store/types/projects";

function ProjectDisplay({ currentProject }: { currentProject: Project }) {
  const renderProjectDetails = (projectName) => {
    switch (projectName) {
      case "project1":
        return <div>Cool project</div>;
      default:
        return <div>Project details not yet defined</div>;
    }
  };

  return (
    <div id="projectView">
      <GeneralProjectInfo
        name={currentProject.name}
        skills={currentProject.skills}
        description={currentProject.description}
      />
      {renderProjectDetails(currentProject.name)}
    </div>
  );
}

export default ProjectDisplay;
