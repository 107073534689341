import React, { useContext, useState } from "react";
import Collapsible from "./Collapsible";
import { useLocation } from "react-router-dom";
import { Topic } from "../../../../store/types/topics";
import { Project } from "../../../../store/types/projects";
import { fileSeparator } from "../Window";
import { ProjectExplorerSettingsContext } from "store/ProjectExplorerSettingsContext";
import { Skill } from "store/types/skills";

const urlHashProjectIsContainedInProjects = (
  projects: Project[],
  topic: Topic,
  hash: string
) => {
  if (!hash) {
    return false;
  } else if (hash === "#") {
    return false;
  }
  const hashedDirectory = hash.split("#")[1];
  const paths = hashedDirectory.split("-");
  const hashedTopic = paths[0].replace("%20", " ");
  if (topic === hashedTopic) {
    return true;
  } else if (paths.length > 2) {
    const hashedProject = paths.at(-1);
    return (
      projects.find((project) => project.name === hashedProject) !== undefined
    );
  }
  return false;
  // const paths;
};

function SideNavbar({
  handleChangeDirectory,
  projectsGroupedByFolderNameMap,
}: {
  handleChangeDirectory: (directory: string) => void;
  projectsGroupedByFolderNameMap: Map<string, Project[]>;
}) {
  const { hash } = useLocation();
  const { settings } = useContext(ProjectExplorerSettingsContext);
  // const projectGroupedByTopic = getProjectGroupedByTopic(projec, topics);
  const [openStates, setOpenStates] = useState(() => {
    const openStates = {};
    for (const [
      folder_name,
      projects,
    ] of projectsGroupedByFolderNameMap.entries()) {
      const open = urlHashProjectIsContainedInProjects(
        projects,
        folder_name,
        hash
      );
      openStates[folder_name] = open;
    }
    return openStates;
  });

  const collapsibles = [];
  for (const [folderName, projects] of projectsGroupedByFolderNameMap) {
    collapsibles.push(
      <li key={folderName}>
        <Collapsible
          folderName={folderName}
          open={openStates[folderName]}
          handleCollapsible={(e: MouseEvent, folder_name: string) => {
            e.preventDefault();
            setOpenStates(() => {
              return { ...openStates, [folder_name]: !openStates[folder_name] };
            });
          }}
          handleChangeDirectory={handleChangeDirectory}
        >
          {projects.map((project) => {
            if (project.folder_name === folderName) {
              return (
                <li
                  key={project.name}
                  style={{
                    opacity:
                      settings.filter !== "NONE" &&
                      project.skills.some((skill) =>
                        settings.filter.some(
                          (skillFiltered: Skill) =>
                            skillFiltered.id === skill.id
                        )
                      )
                        ? "0.7"
                        : "",
                  }}
                >
                  <a
                    href={`#${project.folder_name}-${project.name}`}
                    onClick={() =>
                      handleChangeDirectory(
                        project.folder_name + fileSeparator + project.name
                      )
                    }
                  >
                    {project.name}
                  </a>
                </li>
              );
            }
          })}
        </Collapsible>
      </li>
    );
  }

  return (
    <nav>
      <ul>
        <li key="hub">
          <a
            className="cursor-pointer"
            href="#"
            onClick={() => {
              handleChangeDirectory("");
            }}
          >
            Go to hub
          </a>
        </li>

        {collapsibles}
        {/* {topics.map((topic, index) => {
          return (
            <li key={index}>
              <Collapsible topic={topic} open={}>
                {projects.map((project, index) => {
                  if (project.topic === topic) {
                    return (
                      <li key={index}>
                        <a
                          href={`#${project.name}`}
                          onClick={() => handleChangeDirectory(project.name)}
                        >
                          {project.name}
                        </a>
                      </li>
                    );
                  }
                })}
              </Collapsible>
            </li>
          );
        })} */}
      </ul>
    </nav>
  );
}

export default SideNavbar;
