import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root.js";
import ErrorPage from "./routes/Error..js";
import Contact from "./routes/Contact.js";
import Home from "./routes/Home.js";
import Projects from "./routes/Projects.js";
import CV from "./routes/CV.js";
import About from "./routes/About.js";
import "./interceptors/fetch-interceptor.js";

//changed from createBrowserRouter to hash router as github pages does not support, see https://stackoverflow.com/questions/71984401/react-router-not-working-with-github-pages

const routes = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "cv",
        element: <CV />,
      },
    ],
  },
];

console.log("basepath used: " + import.meta.env.VITE_BASE);
const router = createBrowserRouter(routes, {
  basename: import.meta.env.VITE_BASE,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
