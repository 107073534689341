import "./Skills.css";
import useTriggerAnimationOnScrollIntoView from "../hooks/useTriggerAnimationOnScrollIntoView";
import { useEffect, useState } from "react";
import { Skill } from "store/types/skills";
import SkillStack from "./SkillStack";
import { Id } from "react-toastify";

export type Topic = {
  id: number;
  name: string;
};

const Skills = ({ styleContainer, showSubSkills }) => {
  const [skills, setSkills] = useState<Skill[]>();
  useTriggerAnimationOnScrollIntoView("#skills-container", "animate-skillbars");

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/skills")
      .then((response) => response.json())
      .then((skills) => {
        if (mount) {
          setSkills(skills);
        }
      })
      .catch((err) => {
        console.error("unable to fetch Skills, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);

  const skillsGroupedPerTopic = skills?.reduce(
    (acc: Map<string, Skill[]>, skill) => {
      const topicName = skill.topic.name;
      if (acc.has(topicName)) {
        const skillsForTopic = acc.get(topicName);
        if (!skillsForTopic) {
          throw new Error("the skills array is not present, logic error");
        }
        skillsForTopic.push(skill);
      } else {
        acc.set(topicName, [skill]);
      }
      return acc;
    },
    new Map<string, Skill[]>()
  );

  return (
    <div id="skills-container" style={styleContainer}>
      {skillsGroupedPerTopic &&
        [...skillsGroupedPerTopic.keys()].map((topicName) => {
          const skills = skillsGroupedPerTopic.get(topicName);
          return (
            <div key={topicName} className="skill-stack-container">
              <h3>{topicName}</h3>
              {skills?.map((skill) => (
                <SkillStack
                  key={skill.id}
                  skill={skill}
                  showSubSkills={showSubSkills}
                />
              ))}
            </div>
          );
        })}
    </div>
  );
};

export default Skills;
