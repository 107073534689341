import { useEffect, useRef, useState } from "react";

import "./Carousel.css";
import { ProjectImageInfo } from "../store/types/projectImageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleDot } from "@fortawesome/free-solid-svg-icons";

//determine the image size

const calculateImageWidth = (
  totalViewportWidthCarousel: number,
  originalImageWidth: number
) => {
  const minimumImagesSizeInPixels = 350;
  const totalPixelWidthCarousel =
    (window.innerWidth - 50) * (totalViewportWidthCarousel / 100);
  let imagesDisplayed = 2.5;
  //if the screen space is ratter small.
  if (totalPixelWidthCarousel < minimumImagesSizeInPixels * imagesDisplayed) {
    imagesDisplayed = 1.2;
  }
  const imageSize = totalPixelWidthCarousel / imagesDisplayed;
  if (imageSize > originalImageWidth) {
    return originalImageWidth;
  }
  return imageSize;
};

const translateMouseMove = (
  e: MouseEvent,
  transform: string,
  speed: number,
  imageWidth: number
) => {
  const imageContainerElement = e.currentTarget;
  if (!imageContainerElement) {
    throw new Error("unkown currenttarget");
  }
  const totalWidth =
    (imageContainerElement as HTMLElement).scrollWidth - 1.2 * imageWidth;
  const originalTranslation =
    transform === ""
      ? 0
      : Number(transform.split("translateX(")[1].slice(0, -3));
  const possibleTranslation = e.movementX * speed + originalTranslation;
  let translation;
  if (possibleTranslation > 0) {
    translation = 0;
  } else if (possibleTranslation < -totalWidth) {
    translation = -totalWidth;
  } else {
    translation = possibleTranslation;
  }
  return translation;
};

const findCurrentFocussedImage = (imageWidth, translateX) => {
  const currentImageIndex = Math.round(Math.abs(translateX) / imageWidth);
  return currentImageIndex;
};

/**
 * It is important for the carousel to now what the width will be relative to the viewport.
 * @param param0
 * @returns
 */
const Carousel = ({
  images,
  aspectratio,
  totalViewportWidthCarousel,
  originalImageWidth,
}: {
  images: ProjectImageInfo[];
  aspectratio: number;
  totalViewportWidthCarousel: number;
  originalImageWidth: number;
}) => {
  const imgCarouselContainerRef = useRef<HTMLElement>();
  const [width, setWidth] = useState(
    calculateImageWidth(totalViewportWidthCarousel, originalImageWidth)
  );
  const [translateX, setTranslateX] = useState(0);
  const height = width / aspectratio;
  const handleMouseMoveRef = useRef<(e: MouseEvent) => void>();
  const currentFocussedImage = findCurrentFocussedImage(width, translateX);

  useEffect(() => {
    const imageElements = imgCarouselContainerRef.current
      ?.children as HTMLCollectionOf<HTMLElement>;
    if (imageElements) {
      for (const imageElement of imageElements) {
        imageElement.style.transform = `translateX(${translateX}px)`;
      }
    }
  }, [translateX]);

  //change image width on resizing of window
  useEffect(() => {
    const handleResize = () => {
      setWidth(
        calculateImageWidth(totalViewportWidthCarousel, originalImageWidth)
      );
    };
    window.addEventListener("resize", handleResize);
    //--> some browser do not fire the resize event on orientation change
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [totalViewportWidthCarousel, originalImageWidth]);

  //change the position of selected image based on slider
  const mouseDown = (e: MouseEvent) => {
    e.preventDefault();

    const imageElements = imgCarouselContainerRef.current
      ?.children as HTMLCollectionOf<HTMLElement>;
    if (!imageElements) {
      return;
    }

    const handleMouseMove = (e: MouseEvent) => {
      const newTranslateX = translateMouseMove(
        e,
        imageElements[0].style.transform,
        1.2,
        width
      );
      setTranslateX(newTranslateX);
    };
    handleMouseMoveRef.current = handleMouseMove;

    imgCarouselContainerRef.current.addEventListener(
      "mousemove",
      handleMouseMove
    );
    document.addEventListener("mouseup", mouseUpHandler, { once: true });
  };

  const mouseUpHandler = (e: MouseEvent) => {
    const handleMouseMove = handleMouseMoveRef.current;
    if (!handleMouseMove) {
      return;
    }
    imgCarouselContainerRef.current.removeEventListener(
      "mousemove",
      handleMouseMove
    );
  };

  return (
    <div className="carousel">
      <div
        className="img-carousel-container"
        ref={imgCarouselContainerRef}
        onMouseDown={mouseDown}
      >
        {images.map((image: ProjectImageInfo) => {
          return (
            <div className="img-container" key={image.src}>
              <img
                height={height}
                width={width}
                alt="image 1"
                src={image.src}
                style={{ aspectRatio: aspectratio }}
              />
              <div className="overlay">
                <p>{image.description}</p>
                <button type="button" onClick={image.onclick}>
                  learn more
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="carousel-navigation-bar">
        {images.map((image, index) => {
          return currentFocussedImage === index ? (
            <FontAwesomeIcon key={image.src} icon={faCircle} size="lg" />
          ) : (
            <FontAwesomeIcon
              key={image.src}
              icon={faCircleDot}
              size="lg"
              onClick={() => {
                setTranslateX(-(width * index));
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
