import React, { FocusEventHandler, useContext } from "react";
import "./ToolbarOptions.css";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ProjectExplorerSettingsContext,
  SortOptions,
  ViewOptions,
} from "../../../../store/ProjectExplorerSettingsContext";
import {
  faFilter,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import SettingsDropdownItem from "./SettingsDropdownItem";
import { Skill } from "store/types/skills";

const ToolbarOptions = ({ skills, handleChangeFilter }) => {
  const { settings, dispatchSetting } = useContext(
    ProjectExplorerSettingsContext
  );

  const handleClickDropdown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const open = "open";
    const classList = e.currentTarget.classList;
    if (classList.contains(open)) {
      classList.remove(open);
      e.currentTarget.parentNode?.setAttribute("aria-expanded", "false");
    } else {
      classList.add(open);
      e.currentTarget.parentNode?.setAttribute("aria-expanded", "true");
    }
  };

  const handleFocusOutDropdown = (e: React.FocusEvent<HTMLLIElement>) => {
    e.stopPropagation();
    const dropdown = e.currentTarget;
    const dropdown_2 =
      e.relatedTarget?.parentElement?.parentElement?.parentElement;
    if (dropdown === dropdown_2) {
      return;
    }
    e.currentTarget.setAttribute("aria-expanded", "false");
    e.currentTarget
      .querySelector(".dropdown-main-item")
      ?.classList.remove("open");
  };

  return (
    <ul id="toolbarOptionsContainer">
      <li
        className="dropdown"
        aria-expanded="false"
        onBlur={handleFocusOutDropdown}
      >
        <button onClick={handleClickDropdown} className="dropdown-main-item">
          Sort <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">
          {Object.values(SortOptions).map((option: SortOptions) => {
            return (
              <li key={option}>
                {
                  <SettingsDropdownItem
                    onClick={() => {
                      dispatchSetting({ type: "SORT", option: option });
                    }}
                    name={option.toLowerCase()}
                    selected={option === settings.sort}
                  />
                }
              </li>
            );
          })}
        </ul>
      </li>
      <li
        className="dropdown"
        aria-expanded="false"
        onBlur={handleFocusOutDropdown}
      >
        <button onClick={handleClickDropdown} className="dropdown-main-item">
          Filter{" "}
          {settings.filter === "NONE" ? (
            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faFilter} fixedWidth size="xs" />
          )}
        </button>
        <ul className="dropdown-list">
          {settings.filter !== "NONE" && (
            <li key="remove-filter">
              <SettingsDropdownItem
                onClick={() => {
                  handleChangeFilter("NONE");
                }}
                name={
                  <>
                    remove filter
                    <FontAwesomeIcon icon={faFilterCircleXmark} />
                  </>
                }
                selected={false}
              />
            </li>
          )}
          {skills.map((skill: Skill) => {
            return (
              <li key={skill.id}>
                <SettingsDropdownItem
                  onClick={() => {
                    const filter = [skill];
                    handleChangeFilter(filter);
                  }}
                  name={skill.name}
                  selected={
                    settings.filter !== "NONE" &&
                    settings.filter.some(
                      (filterSkill) => filterSkill.id === skill.id
                    )
                  }
                />
              </li>
            );
          })}
        </ul>
      </li>
      {/**VIEW IS ONLY SUPPORTED ON DESKTOP CAUSE OF RESPONSIVENESS ISSUES. */}
      <li
        className="dropdown desktop-only"
        aria-expanded="false"
        onBlur={handleFocusOutDropdown}
      >
        <button onClick={handleClickDropdown} className="dropdown-main-item">
          View <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">
          {Object.values(ViewOptions).map((option) => {
            return (
              <li key={option}>
                <SettingsDropdownItem
                  onClick={() => {
                    dispatchSetting({ type: "VIEW", option: option });
                  }}
                  name={option.toLowerCase()}
                  selected={settings.view === option}
                />
              </li>
            );
          })}
        </ul>
      </li>
      <li
        className="dropdown"
        aria-expanded="false"
        onBlur={handleFocusOutDropdown}
      >
        <button onClick={handleClickDropdown} className="dropdown-main-item">
          ... <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">{<li>to be implemented</li>}</ul>
      </li>
    </ul>
  );
};

export default ToolbarOptions;
